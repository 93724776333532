import { ExternalLinkSquareAlt, Github } from '@vicons/fa';

export default {
    works: [
        {
            overline: 'POS Software',
            title: 'Divipay',
            des: "Divipay is a POS solution system for managing food chain stores, real-time reporting,inventory management, call center - cashiers - delivery modules, supplier management, userroles and permissions that adapt to all your needs and goals, managing operations of allbranches",
            techs: ['Vuetify', 'Vue2'],
            links: [
                {
                    link: 'https://divipay.net/',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://divipay.net/',
            img: require('../../assets/projects/divipay.png'),
        },
        {
            overline: 'Search Engine Project',
            title: `Get GIF's`,
            des: "gifs browser is app you can browse gifs from Giphy, it's show trending gifs, search about specific tags and interact with all gifs",
            techs: ['vue', 'vuetify','Giphy API', 'javascript'],
            links: [
                {
                    link: 'https://github.com/ibraahim6/gifs-browser',
                    icon: Github,
                    tooltip: 'Open in Github',
                },
            ],
            imageLink: 'https://github.com/ibraahim6/gifs-browser',
            img: require("../../assets/projects/gifs browser.png"),
        },
        {
            overline: 'Personal Website',
            title: 'Portfolio',
            des: "It's is a new cool look for my portfolio based on Brittany's and brojenuel design.",
            techs: ['vue', 'PWA', 'javascript'],
            links: [
                {
                    link: 'https://ibraahim6.com',
                    icon: ExternalLinkSquareAlt,
                    tooltip: 'Open Link',
                },
            ],
            imageLink: 'https://ibraahim6.com',
            img: require('../../assets/projects/ibraahim6.png'),
        },
    ],
};
