import { Html5, Vuejs } from '@vicons/fa';

export default [
    {
        title: 'ELDalell',
        des: '<p>ElDallel is an Egyptian products ordering services. Customers can order their daily needs from more than a market with a couple taps, and ElDallel will manage the rest.</p>',
        headIcon: 'chat',
        links: [],
        techs: [
            {
                name: 'html&css',
                icon: Html5,
            },
            {
                name: 'VueJS',
                icon: Vuejs,
            },
        ],
    },
    {
        title: 'Premidic',
        des: '<p>Premidic is an easy way to follow up with your doctors, perceptions, and medicines.        </p>',
        headIcon: 'chat',
        links: [],
        techs: [
            {
                name: 'html&css',
                icon: Html5,
            },
            {
                name: 'VueJS',
                icon: Vuejs,
            },
        ],
    },
    {
        title: 'iMovie',
        des: '<p>iMovie is a free streaming website. Users can Watching Arabic and English Movies and download them with high quality</p>',
        headIcon: 'chat',
        links: [],
        techs: [
            {
                name: 'html&css',
                icon: Html5,
            },
            {
                name: 'VueJS',
                icon: Vuejs,
            },
        ],
    },
];
